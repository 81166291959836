<template>
  <v-navigation-drawer
    v-if="data"
    :key="`${data.id}:${name}`"
    v-model="open"
    :width="$vuetify.breakpoint.mobile ? '100%' : 'auto'"
    app
    xclipped
    right
    temporary
    class="elevation-8"
  >
    <v-card-title>
      <div>
        <v-icon left>
          fal fa-shield-virus
        </v-icon>
        {{ data?.id ? name.en : 'Create New Resistance' }}
      </div>
      <v-spacer />
      <v-btn
        icon
        tile
        right
        color="grey"
        @click.stop="$emit('close-drawer')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-tabs-items v-model="tab">
      <v-tabs
        v-model="tab"
        background-color="primary lighten-1"
        grow
        dark
      >
        <v-tab
          v-for="tabIcon in [
            { icon: 'fal fa-circle-info', disabled: false },
            { icon: 'fal fa-dna', disabled: !data.id },
            { icon: 'fal fa-tachometer-alt', disabled: !data.id },
            { icon: 'fal fa-pills', disabled: !data.id },
            { icon: 'fal fa-books', disabled: !data.id },
            { icon: 'fal fa-language', disabled: !data.id }
          ]"
          :key="tabIcon.icon"
          class="px-0"
          style="min-width: 60px"
          :disabled="tabIcon.disabled"
        >
          <v-icon>{{ tabIcon.icon }}</v-icon>
        </v-tab>
      </v-tabs>
      <v-container class="spy-0">
        <v-form
          ref="facilityForm"
          v-model="valid"
          class="mt-3"
          @submit.prevent="updateResistance"
        >
          <v-tab-item>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name.en"
                  :label="$t('name') | capitalize"
                  hint="How the resistance name will appear in lists"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="data.short_name"
                  label="Short Name"
                  hint="How the resistance name will appear in smart sentences"
                  dense
                />
              </v-col>
              <!-- <v-col cols="12">
                <v-autocomplete
                  v-model="data.nih_subclasses"
                  :items="nih_subclasses"
                  label="NIH Subclasses"
                  cache-items
                  small-chips
                  multiple
                  dense
                />
              </v-col> -->
              <v-col cols="12">
                <v-slider
                  v-model="data.score"
                  label="ArkScore"
                  :min="1"
                  :max="7"
                  dense
                  thumb-label="always"
                  :thumb-size="28"
                  ticks="always"
                  class="mt-2"
                  :color="colors[data.score]"
                  :track-color="colors[data.score]"
                  hide-details
                >
                  <template #label>
                    <v-img
                      src="/img/arkscore-logo-name.svg"
                      width="90"
                      contain
                    />
                  </template>
                </v-slider>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="data.description"
                  label="Description"
                  auto-grow
                  dense
                />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item v-if="data.id">
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="sortedGenes"
                  :items="genes.filter(x => x.gene).sort((a, b) => a.gene.localeCompare(b.gene))"
                  label="Genes"
                  :search-input.sync="search"
                  hide-selected
                  multiple
                  small-chips
                  deletable-chips
                  item-text="gene"
                  item-value="gene"
                  class="mt-4"
                  dense
                >
                  <template #selection="{ item }">
                    <v-chip
                      v-bind="item"
                      :input-value="item"
                      small
                      class="mb-1 me-0 px-2"
                      block
                    >
                      <v-icon
                        small
                        left
                        class="me-1"
                        @click="sortedGenes.splice(sortedGenes.indexOf(item), 1)"
                      >
                        mdi-close-circle-outline
                      </v-icon>
                      {{ item.gene }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item v-if="data.id">
            <v-row>
              <v-col>
                <v-list
                  dense
                >
                  <v-list-item
                    v-for="factor in score_factors"
                    :key="factor.value"
                    dense
                  >
                    <v-list-item-action>
                      <v-switch
                        v-model="data.score_factors"
                        :value="factor.value"
                        dense
                      />
                    </v-list-item-action>
                    <v-list-item-content @click="data.score_factors[factor.value] = !data.score_factors[factor.value]">
                      {{ factor.text }}
                      <v-list-item-subtitle v-if="factor.subtitle">
                        {{ factor.subtitle }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item v-if="data.id">
            <v-row>
              <v-col
                cols="12"
                class="text-caption"
              >
                Drugs affected by {{ name.en }} Resistance
              </v-col>
              <v-col>
                <!-- {{ data.drugs.map(x => x.name) }} -->
                <drug-picker
                  v-model="data.drugs"
                  :value="data.drugs"
                  prepend-inner-icon="fal fa-fw fa-pills"
                  multiple
                  hide-arkscore
                  hide-selected
                  no-display
                  dense
                  solo
                  filled
                  rounded
                  no-hint
                  :max-width="100"
                  menu-props="closeOnClick, closeOnContentClick"
                  small-chips
                  label="Drugs in this Category"
                />
                <v-list
                  class="mt-n4"
                  dense
                >
                  <v-list-item
                    v-for="drug in orderBy(data.drugs, 'name.en')"
                    :key="drug.id"
                  >
                    <v-list-item-title>
                      {{ drug.name.en }}
                    </v-list-item-title>
                    <v-list-item-action
                      class="ma-0"
                      @click="data.drugs.splice(data.drugs.indexOf(drug), 1)"
                    >
                      <v-btn
                        icon
                        tile
                        small
                      >
                        <v-icon small>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item v-if="data.id">
            <add-reference :references.sync="data.references" />
          </v-tab-item>

          <v-tab-item v-if="data.id">
            <v-text-field
              v-for="language in $languages.filter(x => x.value != 'en')"
              :key="language.value"
              v-model="name[language.value]"
              :label="language.text"
              :lang="language.value"
            >
              <template #prepend>
                <country-flag
                  :country="language.flag || language.value"
                  class="mt-n2"
                />
              </template>
              <template
                v-if="language.value !== 'en' && name.en"
                #append
              >
                <v-btn
                  icon
                  @click="translateText(name.en, language.value, v => $set(data.name, language.value, v))"
                >
                  <v-icon small>
                    fa fa-language
                  </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-tab-item>
        </v-form>
      </v-container>
    </v-tabs-items>
    <template #append>
      <v-divider />
      <v-col>
        <v-btn
          :loading="loading"
          type="submit"
          color="success"
          block
          @click="updateResistance"
        >
          {{ data?.id ? 'Update' : 'Create' }} Resistance
        </v-btn>
      </v-col>
    </template>
  </v-navigation-drawer>
</template>
<script>
  import Vue2Filters from 'vue2-filters';

  export default {
    components: {
      DrugPicker: () => import('@/components/drug/DrugPicker.vue'),
      AddReference: () => import('@/components/reference/AddReference.vue'),
      CountryFlag: () => import('vue-country-flag'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    props: {
      open: Boolean,
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      tab: 'info',
      valid: false,
      loading: false,
      search: null,
      tags: [],
      genes: [],
      nih_subclasses: [],
      rules: {
        name: [
          v => !!v || this.$t('required'),
        ],
      },
      score_factors: [
        { value: 'other_associations', text: 'Associated with other resistances' },
        { value: 'precautions_required', text: 'Contact precautions may be needed' },
        { value: 'cdc_threat', text: 'Listed in the CDC AR threat report' },
        { value: 'multiple_classes', text: 'May affect multiple classes of antibiotics' },
        { value: 'gram_negative', text: 'Associated with gram negative bacteria' },
        { value: 'gram_positive', text: 'Associated with gram positive bacteria' },
      ],
      colors: {
        1: '#00814e',
        2: '#4cb648',
        3: '#c6d935',
        4: '#ffcc05',
        5: '#f79839',
        6: '#ec2024',
        7: '#ae1618',
      },
    }),
    computed: {
      name: {
        get () {
          return this.data.id ? this.data.name : { en: null }
        },
        set (v) {
          this.$set(this.data, 'name', v)
        },
      },
      sortedGenes: {
        get () {
          return [...this.data.genes].filter(r => r.gene).sort((a, b) => a.gene.localeCompare(b.gene))
        },
        set (v) {
          this.$set(this.data, 'genes', v)
        },
      },
    },
    watch: {
      search () {
        this.fetchGenes()
      },
      'data.id' () {
        this.fetchGenes()
        this.fetchSubclasses()
      },
    },
    // created () {
    // },
    methods: {
      updateResistance () {
        if (this.valid) {
          this.loading = true
          this.axios.request({
            method: this.data.id ? 'patch' : 'post',
            url: this.data.id ? 'admin/resistances/' + this.data.id : 'admin/resistances',
            data: this.data,
          })
            .then(() => {
              this.$root.$emit('fetch-resistances')
              this.$emit('close-drawer')
              this.$toast.success('Resistance updated')
            })
            .catch(error => {
              this.$toast.error(error)
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
      deleteResistance () {
        this.$dialog.warning({
          text: 'Do you really want to delete this resistance?',
          title: 'Delete Resistance',
          icon: 'fas fa-exclamation-triangle mr-2',
          actions: {
            true: {
              text: 'Yes', color: 'red',
            },
            false: 'No',
          },
        })
          .then((res) => {
            if (res) {
              this.axios.delete('admin/resistances/' + this.data.id)
                .then(() => {
                  this.$root.$emit('fetch-resistances')
                  this.$toast.success('Resistance deleted')
                })
                .catch(error => {
                  this.$toast.error(error)
                })
            }
          })
      },
      fetchGenes () {
        const promise = this.axios.get('admin/resistances/genes', {
          params:
            {
              count: 20,
              search: this.search,
              resistance: this.name.en,
            },
        })
        return promise.then((response) => {
          this.genes = response.data.data
        }).catch(error => {
          this.$toast.error(error)
        })
      },
      fetchSubclasses () {
        const promise = this.axios.get('data/options', {
          params:
            {
              field: 'subclass',
            },
        })
        return promise.then((response) => {
          this.nih_subclasses = response.data
        }).catch(error => {
          this.$toast.error(error)
        })
      },
    },
  }
</script>
